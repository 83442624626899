(function () {
    var $q = document.querySelector.bind(document),
        $qAll = document.querySelectorAll.bind(document);

    $('[href=#]').click(function (e) {
        e.preventDefault();
    });

    $('.body-wrapper').css('opacity', 1);

    $('.fancybox').fancybox({
        padding: 0,
        margin: 30
    });


    /* -----------------------------------------------------------------------
     * Banners
     * ---------------------------------------------------------------------- */
    function bigBannerResize() {
        fitToHeight('.big-banner', 1);
    }

    addLoadEvent(bigBannerResize);
    $(window).bind('debouncedresize', bigBannerResize);

    var $slide = $qAll('.cycle-slideshow');
    for (var i = 0; i < $slide.length; i++) {
        $($slide[i]).cycle({
            width: '100%',
            fit: true,
            cleartypeNoBg: true,
            fx: $($slide[i]).data('cycle-fx'),
            timeout: $($slide[i]).data('cycle-timeout'),
            speed: $($slide[i]).data('cycle-speed')
        });
    }


    /* -----------------------------------------------------------------------
     * Content
     * ---------------------------------------------------------------------- */
    fitToHeight('.main', 0);


    /*
     * Isopote
     */
    var $container = $('.isotope-wrap');
    $container.isotope({
        itemSelector: '.isotope-item',
        animationEngine: 'best-available',
        animationOptions: {
            duration: 200,
            queue: false
        },
        layoutMode: 'fitRows'
    });

    function splitIsotopeColumns() {
        var winWidth = $container.width(),
            columnNumb = 1;

        if (winWidth > 1600) {
            columnNumb = 5;
        } else if (winWidth > 1200) {
            columnNumb = 4;
        } else if (winWidth > 900) {
            columnNumb = 3;
        } else if (winWidth > 600) {
            columnNumb = 2;
        } else if (winWidth > 300) {
            columnNumb = 1;
        }

        return columnNumb;
    }

    function setIsopoteColumns() {
        var winWidth = $container.width(),
            columnNumb = splitIsotopeColumns(),
            postWidth = Math.floor(winWidth / columnNumb);

        $container.find('.isotope-item img').css('width', postWidth);
    }

    function reLayoutIsotope() {
        setIsopoteColumns();
        $container.isotope('reLayout');
    }

    $container.imagesLoaded(reLayoutIsotope);
    addLoadEvent(reLayoutIsotope);
    $(window).bind('debouncedresize', reLayoutIsotope);


    /* -----------------------------------------------------------------------
     * Reservas
     * ---------------------------------------------------------------------- */
    var FormReserva = document.form_reserva;

    if (isElement(FormReserva)) {
        FormReserva.onsubmit = function () {
            var dataChegada = FormReserva.dataChegada.value.trim(),
                dataPartida = FormReserva.dataPartida.value.trim(),
                send = true;

            if (isValidDate(dataChegada) && isValidDate(dataPartida)) {
                var dataChegadaArray = dataChegada.split('/');
                var dataPartidaArray = dataPartida.split('/');

                var dataChegadaCompare = new Date(dataChegadaArray[2], dataChegadaArray[1], dataChegadaArray[0]);
                var dataPartidaCompare = new Date(dataPartidaArray[2], dataPartidaArray[1], dataPartidaArray[0]);

                dataChegadaCompare = dataChegadaCompare.getTime();
                dataPartidaCompare = dataPartidaCompare.getTime();

                if (dataChegadaCompare >= dataPartidaCompare) {
                    $(FormReserva.dataChegada).addClass("error");
                    $(FormReserva.dataPartida).addClass("error");
                    send = false;
                }
            }

            return send;
        };


        function bloqueiaData() {
            var data_hoje = new Date();

            var data = $(".datepicker_chegada").val();
            var dataFormatada = data.split("/");

            var dataInicio = 1;
            if (dataFormatada[0] == (data_hoje.getDate() + 1)) {
                dataInicio = 2;
            }
            return dataInicio;
        }

        /* Init */
        $(".datepicker").datepicker();

        $(".datepicker_partida").datepicker({
            minDate: 2
        });

        $(".datepicker_chegada").datepicker({
            minDate: bloqueiaData()
        }).change(function () {
            test = $(this).datepicker('getDate');
            testm = new Date(test.getTime());
            testm.setDate(testm.getDate() + 2);
            $(".datepicker_partida").datepicker("option", "minDate", testm);
        });
    }
})();
