var $q = document.querySelector.bind(document),
    $qAll = document.querySelectorAll.bind(document);

function replaceAll(a, b, d) {
    "use strict";
    for (var c = a.indexOf(b); -1 < c;) {
        a = a.replace(b, d), c = a.indexOf(b);
    }
    return a;
}

function isValidEmail(a) {
    var b = !1;
    if ("undefined" != typeof a && (a = a.match(/(\w+)@(.+)\.(\w+)$/), null != a && (2 == a[3].length || 3 == a[3].length))) b = !0;
    return b;
}
function outerWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
}
function outerHeight(el) {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}

function fitToHeight(el, type) {
    function fit(el) {
        var contentHeight = $(window).height() - outerHeight($q('.header')) - outerHeight($q('.footer'));

        if (type == 1) {
            $(el).css('height', contentHeight);
        } else {
            $(el).css('min-height', contentHeight);
        }
    }

    fit(el);
    addLoadEvent(fit);
    $(window).bind('debouncedresize', fit);
}

function isValidDate(s) {
    var bits = s.split('/');
    var y = bits[2],
        m = bits[1],
        d = bits[0];
    // Assume not leap year by default (note zero index for Jan)
    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // If evenly divisible by 4 and not evenly divisible by 100,
    // or is evenly divisible by 400, then a leap year
    if ((!(y % 4) && y % 100) || !(y % 400)) {
        daysInMonth[1] = 29;
    }
    return d <= daysInMonth[--m]
}

function isNode(o) {
    return (
        typeof Node === "object" ? o instanceof Node :
        o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
    );
}

function isElement(o) {
    return (
        typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
        o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
    );
}
